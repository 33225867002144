<template>
  <ContentSection>
    <div class="bg-black">
      <Container indent="center">
        <div class="py-20 text-white">
          <h2 class="text-xl font-bold">
            {{ data.teaser_title }}
          </h2>
          <div
            v-if="!loading"
            class="mt-12 grid grid-cols-2 items-center gap-4 md:grid-cols-3 md:gap-10 md:gap-x-20"
          >
            <ShopCategoryBrandLogo
              v-for="item in items"
              :key="item.category.id"
              :category="item.category"
              :image="item.image"
              link
            />
          </div>
        </div>
      </Container>
    </div>
  </ContentSection>
</template>

<script setup lang="ts">
import { type AssetFragment } from '#gql'

type Brand = {
  category_ids: string[]
  logo: AssetFragment
}

const props = defineProps<{
  data: {
    teaser_title: string
    brands: Brand[]
  }
}>()

const { advancedSearch } = useCategorySearch()

const categoryIds = computed(() =>
  props.data.brands.map((brand) => brand.category_ids).flat()
)

const { data: categories, status } = await useAsyncData(
  `categories-${categoryIds.value.join('-')}`,
  async () => {
    return await advancedSearch({
      query: {
        filter: [
          {
            type: 'multi',
            operator: 'OR',
            queries: categoryIds.value.map((id) => ({
              type: 'equals',
              field: 'id',
              value: id,
            })),
          },
        ],
        associations: {
          media: {},
          seoUrls: {},
        },
      },
    })
  },
  {
    lazy: true,
  }
)

const loading = computed(() => status.value === 'pending')

const items = computed(() =>
  props.data.brands.map((brand) => ({
    category: categories.value?.find((category) =>
      brand.category_ids.includes(category.id)
    ),
    image: brand.logo,
  }))
)
</script>

<style scoped>
::v-deep(.swiper-wrapper) {
  @apply items-stretch;
}
::v-deep(.swiper-button-next),
::v-deep(.swiper-button-prev) {
  @apply top-0 mt-0 h-full w-8;

  &::after {
    @apply size-6 rounded-sm bg-white text-black;
    @apply flex items-center justify-center;
    @apply text-sm;
  }
}
::v-deep(.swiper-button-disabled) {
  @apply pointer-events-auto opacity-100;
}
::v-deep(.swiper-button-next) {
  @apply right-0 pr-2;
}
::v-deep(.swiper-button-prev) {
  @apply left-0 pl-2;
}
</style>
